<template>
  <div class="module_one_block">
    <div class="module_one_main_block">
      <div class="module_one_main_container w-100">
        <div class="notifications_desc w-100" style="background: none">
          <div class="row mb-4">
            <div class="title col-md-6">
              <h3 class="name" v-if="currentTab === 'new'">
                {{ $t("New")}}
              </h3>
              <h3 class="name" v-if="currentTab === 'all'">
                {{ $t("Read")}}
              </h3>
            </div>
            <div class="links text-right col-md-6">
              <el-button
                style="border-radius: 4px 0 0 4px"
                :type="currentTab !== 'all' ? 'primary' : 'secondary'"
                @click="changeStatus('new')"
              >{{ $t("New")}}</el-button>
              <el-button
                class="ml-0"
                style="border-radius: 0 4px 4px 0"
                :type="currentTab === 'all' ? 'primary' : 'secondary'"
                @click="changeStatus('all')"
              >{{ $t("Read")}}</el-button>
            </div>
          </div>
          <div
            class="notifications_desc_item"
            style="background: none"
            v-for="interview in interviews"
          >
            <div class="notifications_desc_item_label">
              {{ $t('invitation_to_an_interview') }}
            </div>
            <div class="notifications_desc_item_text">
              <div v-if="currentTab === 'new'" class="notifications_desc_item_text_mark" @click="respond(interview.id)">{{ $t('Respond') }}</div>
              <div class="notifications_desc_item_title">{{ interview.sender }}</div>
              <div class="notifications_desc_item_message" v-html="interview.text"></div>
              <div v-if="currentTab === 'all' && interview['respond_text']" class="notifications_desc_item_message">
                <h6>Ваш ответ:</h6>
                <div v-html="interview['respond_text']"></div>
              </div>
              <div class="notifications_desc_item_time">{{ formatDate(interview.date) }}</div>
            </div>
          </div>
          <MyPagination
            :totalPages="totalPages"
            :total="total"
            :perPage="perPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPagination from "@/components/MyPagination.vue";

export default {
  name: 'InterviewsHome',
  components: {
    MyPagination,
  },
  data() {
    return {
      currentTab: 'new',
      interviews: [],
      sortingInterviews: [],
      currentPage: 1,
      perPage: 12,
      total: 0,
      totalPages: 0,
      showCourseDuration: true,
      showSorting: true,
      sortingInterviewsStatus: '',
    }
  },
  methods: {
    respond(id) {
      this.$prompt('Текст сообщении', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Откликнуться',
        cancelButtonText: 'Отклонить',
      }).then((text) => {
        this.$http.post(API_ROOT + `/api/interviews/respond`, {
          interview_id: id,
          respond: text.value,
          status: 'accepted'
        }).then((res) => {
          console.logs(res)
        }).catch((err) => {

        });
      }).catch((action) => {
        if (action === 'cancel') {
          this.$http.post(API_ROOT + `/api/interviews/respond`, {
            interview_id: id,
            status: 'declined'
          }).then((res) => {
            console.log(res)
          }).catch((err) => {
          });
        }
      })
    },
    changeStatus(status) {
      this.currentTab = status;
      this.getInterviews(this.currentPage)
    },
    goToCourse(slug) {
      // this.$router.push(`/course/${slug}`)
      this.$router.push({ name: 'about-course', params: { slug } })
    },
    formatDate(date) {
      const dt = new Date(date)
      const year = dt.getFullYear().toString().substring(2)
      const month = ((dt.getMonth() + 1) > 9 ? '' : '0') + (dt.getMonth() + 1)
      const day = (dt.getDate() > 9 ? '' : '0') + dt.getDate()
      const hours = ("0" + dt.getHours()).slice(-2)
      const minutes = ("0" + dt.getMinutes()).slice(-2)

      return hours + ':' + minutes + ' ' + day + '.' + month + '.' + year;
    },
    getInterviews(page) {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/interviews?page=${page}&status=${this.currentTab}`)
        .then(res => {
          this.interviews = res.body
          console.log(this.interviews)

          // this.interviews = this.$i18n.locale === 'ru'
          //   ? this.interviews.filter(course => course.language === 'Русский')
          //   : this.interviews.filter(course => course.language === 'Казахский')

          // this.sortingInterviews = this.interviews
          this.total = res?.body?.meta?.total
          // this.perPage = res?.body?.meta['per_page']
          // this.totalPages = res?.body?.meta['last_page']
          this.sortingInterviewsStatus = this.$router.history.current.params.type
          //this.sortingInterviewsStatus = 'active'
        })
        .catch(err => console.log(err))
        .finally(() => {

        })
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getInterviews(page)
    },
    getProgressWidth(progress) {
      if (progress > 0 && progress < 40)
        progress = 40
      return progress + '%'
    },
  },
  computed: {
    type: function () {
      return this.$route.params.type
    }
  },
  watch: {
    sortingInterviewsStatus() {
      if (this.sortingInterviewsStatus === 'active') {
        this.sortingInterviews = this.interviews.filter(course => course.progress < 100)
      } else if (this.sortingInterviewsStatus === 'passed') {
        this.sortingInterviews = this.interviews.filter(course => course.progress === 100)
      } else {
        this.sortingInterviews = this.interviews
      }
    },
    '$route'(to) {
      this.sortingInterviewsStatus = to.params.type
    }
  },
  mounted() {
    this.getInterviews(this.currentPage)
  }
}
</script>

<style lang="scss" scoped>
.courseslist-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
  padding-top: 60px;

  &_mobile-title {
    display: none;
  }
}
.courseslist {
    width: calc(100% - 400px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    &_course {
        background: white;
        cursor: pointer;
    }
}
.courseslist {
  display: flex;
  flex-wrap: wrap;

  &_course {
    width: 290px;
  }
}
.courses_list_item .courses_list_item_img {
  height: 140px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.courses_list_item .courses_list_item_desc .courses_list_item_desc_text .courses_list_item_title {
  min-height: 48px;
}

.courses_list_item_bar {
  border-radius: 20px;
  padding: 5px;
  padding-left: 20px;
}

.courses_list_item_result {
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  color: #0B1F35;
}

.my_courses_download_certificate {
  background: #007BFC;
  color: #fff;
}

.my_courses_view_course {
  margin-top: 0;
}

.my_courses_view_course, .my_courses_download_certificate {
  padding-left: 30px;
}

.my_courses_view_course::before, .my_courses_download_certificate::before{
  top: 9px
}

.my_courses_status_done.active {
  background: #007BFC;
  color: #fff;
}

@media (max-width: 2000px) {
  .courseslist {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1700px) {
  .courseslist {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .courseslist-wrapper {
    flex-direction: column;
    gap: 20px;

    &_mobile-title {
      display: block;
      font-family: 'RoadRadio';
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .courseslist {
      width: 100%;
    }
    .my_courses_filter_container {
      display: none;
    }
  }
}

@media screen and (max-width: 460px) {
  .courseslist_course {
    width: 100%;
  }
}
</style>
